.explanation {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
  width: 75%;
}

.explanation__btn {
  font-size: 14px;
  font-weight: 600;
  color: #2f327d;
  background-color: transparent;
}
.explanation__btn:hover {
  font-weight: 700;
}
.explanation__text {
  font-size: 16px;
  color: black;
}
